<template>
  <v-col cols="12">
    <v-alert
      dense
      width="100%"
      class="mb-0"
      :prominent="prominent"
      :text="text"
      border="left"
      :type="type"
      :class="$vuetify.breakpoint.mdAndDown ? 'alert-mobile' : ''"
    >
      <template v-if="login">
        <div>{{ item }}</div>
        <div>
          <v-btn @click.prevent="deslogarUsuario">Sair</v-btn>
        </div>
      </template>
      <v-row v-if="voltar" align="center">
        <v-col class="grow">{{ item }}</v-col>
        <v-col class="shrink">
          <v-btn @click="backHistory">Voltar</v-btn>
        </v-col>
      </v-row>
      <div v-if="!login && !voltar">{{ item }}</div>
    </v-alert>
  </v-col>
</template>

<script>
export default {
  name: "AlertBar",
  props: {
    type: String,
    item: [String, Array],
    prominent: Boolean,
    text: Boolean,
    login: {
      type: Boolean,
      default: false
    },
    voltar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    backHistory() {
      this.$router.go(-1);
    },
    deslogarUsuario() {
      window.localStorage.last_route = this.$router.currentRoute.path;
      this.$store.commit("UPDATE_AUTO_REDIRECT", true);
      this.$store.dispatch("deslogarUsuario").then(() => {
        this.$router.push("/login");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .alert-mobile {
  .v-alert__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .v-alert__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
